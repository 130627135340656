import React from 'react';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';

import Loader from '../Loader';
import ProductCard from '../ProductCard';

const ProductContents = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: calc(100% + 32px);
    margin-left: -16px;
  }
`;

const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(30%), 1fr));
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
`;

type ProductDisplayProps = {
  products: Shopify.ProductEdge[];
  canLoadMore: boolean;
  loading: boolean;
  loadMore: () => void;
  isOnVinylCollectionPage?: boolean;
  titleForTracking?: string;
  query?: string;
};

const ProductsDisplay: React.FC<ProductDisplayProps> = ({
  products,
  canLoadMore,
  loading,
  loadMore,
  isOnVinylCollectionPage,
  titleForTracking,
  query,
}: ProductDisplayProps) => (
  <ProductContents>
    <ProductWrapper>
      {products.map(({ node }) => (
        <ProductCard
          key={node.id}
          product={node}
          isOnVinylCollectionPage={isOnVinylCollectionPage}
          titleForTracking={titleForTracking}
          query={query}
        />
      ))}
    </ProductWrapper>
    {canLoadMore && (
      <InView as="div" onChange={(inView, entry) => inView && canLoadMore && loadMore()}>
        {loading && <Loader />}
      </InView>
    )}
  </ProductContents>
);

export default ProductsDisplay;
