import React, { useRef } from 'react';
import styled from 'styled-components';
import { Caption } from '../Typography';

const GITDCreatorFilterContainer = styled.div`
  display: flex;
  gap: 14px;
  margin-top: -16px;
  margin-bottom: 6px;
  overflow-x: scroll;
  margin-left: -16px;
  padding-left: 16px;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
  touch-action: pan-x;
  margin-right: -16px;
  padding-right: 16px;
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    justify-content: center;
  }
`;

const FilterOption = styled.div<{ selected: boolean }>`
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  background: ${({ selected, theme }) => (selected ? theme.colors.gitdPurple : theme.colors.neutral1)};
  border: 1px solid ${({ theme }) => theme.colors.gitdPurple};
  white-space: nowrap;
  pointer-events: auto;
  user-select: none;
`;

const FilterTitle = styled(Caption)<{ selected: boolean }>`
  z-index: 0;
  color: ${({ selected, theme }) => (selected ? theme.colors.neutral1 : theme.colors.gitdPurple)};
  font-weight: ${({ selected }) => (selected ? '700' : 'normal')};
`;

type GITDCreatorFilterProps = {
  activeToggleKey: CreatorFilterKey;
  handleToggleClick: (key: CreatorFilterKey) => void;
};

export enum CreatorFilterKey {
  All = 0,
  Art = 1,
  Entertainment = 2,
  Gaming = 3,
}

type ToggleItem = {
  key: CreatorFilterKey;
  title: string;
};

const toggleItems: ToggleItem[] = [
  { key: CreatorFilterKey.All, title: 'All GITD' },
  { key: CreatorFilterKey.Art, title: 'Art/Animation' },
  { key: CreatorFilterKey.Entertainment, title: 'Entertainment' },
  { key: CreatorFilterKey.Gaming, title: 'Gaming' },
];

const GITDCreatorFilter: React.FC<GITDCreatorFilterProps> = ({
  activeToggleKey,
  handleToggleClick,
}: GITDCreatorFilterProps) => {
  const toggleRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  return (
    <GITDCreatorFilterContainer>
      {toggleItems.map((item) => (
        <FilterOption
          key={item.key}
          ref={(el) => {
            toggleRefs.current[item.key] = el;
          }}
          selected={item.key === activeToggleKey}
          onClick={() => {
            handleToggleClick(item.key);

            // Scroll into view for the clicked toggle
            toggleRefs.current[item.key]?.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'center',
            });
          }}
        >
          <FilterTitle
            selected={item.key === activeToggleKey}
            onClick={() => {
              handleToggleClick(item.key);
            }}
          >
            {item.title}
          </FilterTitle>
        </FilterOption>
      ))}
    </GITDCreatorFilterContainer>
  );
};

export default GITDCreatorFilter;
